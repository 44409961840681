import React from "react"
import CommunityLayout from "../../components/layouts/communityLayout"
import ImageCard from "../../components/cards/imageCard.js"

import EvansRec from "../../images/evans-rec.jpg"
import EvansHeights from "../../images/evans-heights.jpeg"
import Richey from "../../images/richey-arena.jpg"
import EvansPFA from "../../images/pfa.jpg"

const RecreationPage = props => (
  <CommunityLayout pageTitle="Recreation">

    <div>
      <ImageCard src={EvansRec} alt="">
        <h2>Evans County Recreation Department</h2>
        <p>
          Evans County offers many recreational opportunities. The Evans County
          Recreation Park, located on Highway 280 East, features 40 acres of
          beautiful grounds, including baseball fields, tennis courts, a
          playground and an outdoor shelter. The main park also includes the
          George Neder Community Center, a 15,000 sq ft facility available for
          public use. The Bacon-Ford Park in Claxton offers outdoor basketball
          courts along with a picnic area. All the parks, which include the
          Rocks River Park and Brewton Bridge Park – an area with a boat
          launch/landing on the scenic Canoochee River- are operated by the
          Evans County Recreation Department.{" "}
        </p>
        <a href="http://evansrec.com/" traget="_blank">
          www.evansrec.com
        </a>
      </ImageCard>
      <ImageCard
        src={EvansHeights}
        alt="Evans Heights Golf Course, Claxton Georgia"
      >
        <h2>Evans Heights Golf Course</h2>
        <p>
          Evans Heights Golf Course, located at 415 Golf Club Drive just off
          Highway 301 South, Claxton, is a nine-hole course offering a wide
          variety of services including golf pro lessons, a full -service
          driving range and short game area.{" "}
        </p>
        <a
          href="https://www.facebook.com/EvansHeightsGolfCourse"
          target="_blank"
        >
          Evans Heights Facebook Page
        </a>
      </ImageCard>
      <ImageCard src={Richey} alt="Richey Arena Claxton Georgia">
        <h2>Richey Arena</h2>
        <p>
          Richey Arena offers exciting barrel races with a fun rodeo-type
          atmosphere.
        </p>
        <a
          href="https://www.facebook.com/groups/RicheyArena"
          target="_blank"
        >
          Richey Arena Facebook Page
        </a>
      </ImageCard>
      <ImageCard src={EvansPFA} alt="Evans County Public Fishing Area">
        <h2>Evans County Public Fishing Area</h2>
        <p>
          The perfect spot for a family day out or a fisherman’s getaway can be found at the Georgia Department of Natural Resources, 
          Evans County Public Fishing Area (PFA).  The PFA is a 372-acrea area, located at 4757 Area Line Road, Claxton, GA, featuring 3 
          lakes ranging in size from 8 to 84 acres.  Not only does the PFA offer ample fishing opportunities with boat ramps and fishing 
          docks, it also features nature trails, picnic sites, primitive camping, an archery range, Event Center (can accommodate up to 
          85 people with a full service kitchen, indoor restrooms, and pavilion), and 22 campsites (14 full size RV sites and 8 tent only 
          with community restrooms and shower facility).  Each campsite contains electrical and water hook-ups, picnic table and fire ring. 
        </p><br />
        <p>
          Contact the Georgia DNR for more information,{" "}
          <a
            href="https://georgiawildlife.com/evans-county-pfa"
            target="_blank"
          >
            www.georgiawildlife.com
          </a>
        </p>
      </ImageCard>
    </div>
  </CommunityLayout>
)

export default RecreationPage
